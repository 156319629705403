export const data = [
    {
        "id": "01",
        "titre": "Grimoire",
        "cover": require("./Grimoire.webp"),
        "description": "Back-End NodeJS d'un site de notation de livres avec gestion de MongoDB.",
        "lien": "https://github.com/UzuRyu/OCGrimoire",
        "tags": [
            "NodeJS",
            "MongoDB",
            "API Rest Express"
        ],
        "probs": "Les images uploadées devaient être optimisées.",
        "solution": "J'ai pensé à supprimer les images uploadées lorsque que l'ajout de livres était refusé car l'image était enregistrée avant l'ajout du livre dans la Base de Données."
    },
    {
        "id": "02",
        "titre": "Kasa",
        "cover": require("./Kasa.webp"),
        "description": "Front-End d'un site de location ReactJS avec pages multiples et éléments interactifs.",
        "lien": "https://github.com/UzuRyu/OCKasa",
        "tags": [
            "React",
            "React Router",
            "Sass"
        ],
        "probs": "La maquette devait être respectée à la lettre.",
        "solution": "J'ai dû être méthodique et faire attention aux Media-Queries. Je devais m’assurer que le rendu final correspondait correctement à la maquette Figma fournie."
    },
    {
        "id": "03",
        "titre": "Booki",
        "cover": require("./Booki.webp"),
        "description": "Création de la page d'accueil d'un site de voyage.",
        "lien": "https://github.com/UzuRyu/OCBooki",
        "tags": [
            "HTML",
            "CSS"
        ],
        "probs": "Je n'étais plus habitué à l'environnement de travail à cause d'une longue période sans avoir programmé.",
        "solution": "Je me suis réhabitué petit à petit en écrivant de petits morceaux de codes pour me remémorer la syntaxe et structure des langages HTML, CSS et JavaScript."
    }
]
 

